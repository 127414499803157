<template>
  <div class="main-view">
    <merchant-list></merchant-list>
  </div>
</template>

<script>
import MerchantList from '@/components/merchants/MerchantList.vue';

export default {
  name: 'MerchantListView',
  components: {
    MerchantList,
  },
};
</script>

<style>

</style>
