<template>
  <div>
    <div>
        <div class="">
          <button
            aria-label="Create Merchant"
            class="button is-primary"
            v-on:click="createMerchant">
            <b-icon icon="plus-box"
              size="is-small">
            </b-icon>
              <p>Create Merchant</p>
          </button>
        </div>
      </div><br>
    <loader v-if="isLoading"></loader>
    <div class="" v-if="merchants">
      <b-table ref="table"
        @select="selected"
        class="table-row"
        hoverable
        selectable
        :data="merchants">
          <b-table-column field="name" current-sort label="Merchant Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="paymentProvider" current-sort
          label="Payment Provider Type" sortable v-slot="props">
            {{ props.row.paymentProvider }}
          </b-table-column>
          <b-table-column field="merchantId"
          current-sort label="Merchant ID" sortable v-slot="props">
            {{ props.row.merchantId }}
          </b-table-column>
          <b-table-column field="status" label="Status" :visible="visible" v-slot="props">
            <b-tag v-if="props.row.status === null">Inactive</b-tag>
          </b-table-column>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  name: 'MerchantList',
  methods: {
    selected(item) {
      this.$router.push({ name: 'merchantEdit', params: { merchantId: `${item.id}` } });
    },
    createMerchant() /* istanbul ignore next */ {
      this.$router.push({ path: 'merchantcreate' });
    },
    async loadAsyncData() {
      this.data = [];
      this.isLoading = true;

      this.dataService.merchants
        .merchantsList(this.$route.params.tenant)
        .then((data) => {
          this.merchants = data.data;
          this.isLoading = false;
        })
        .catch((error) => /* istanbul ignore next */ {
          this.data = [];
          this.isLoading = false;
          throw error;
        });
    },
  },
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
    this.loadAsyncData();
  },
  data() {
    return {
      merchants: '',
      filterableFields: [{
        id: 'name',
        value: 'Name',
        type: 'string',
      }],
      visible: false,
      data: [],
      total: 0,
      isLoading: false,
      sortField: 'name',
      sortOrder: 'ascending',
      defaultSortOrder: 'ascending',
      page: 1,
      pageSize: 10,
    };
  },
};
</script>

<style lang="scss" scoped>
  .bottom-padding {
    padding: 0;
    padding-bottom: 1.5rem;
  }
</style>
